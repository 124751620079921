<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter')}}
                </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">


                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('activity_log_report.action')}}</label>
                                <select name="" id="currency_id" v-model="filters.description" class="custom-select">
                                    <option v-for="(row, index) in actions" :value="row.slug" :key="index">{{ row.title }}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('activity_log_report.from_date')}}</label>
                                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('activity_log_report.to_date')}}</label>
                                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mb-5">
                                <label>{{$t('activity_log_report.user')}}</label>
                                <multiselect v-model="user"
                                             :placeholder="$t('activity_log_report.user')"
                                             label="name"
                                             track-by="id"
                                             :options="users"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getUsers($event)">
                                </multiselect>
                            </div>


                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <!-- End Filter -->
        <div class="card card-custom">
            <div class="card-body">
                <div class="mt-3">
                    <div class="row mb-10">
                        <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                            <h4 class="text-center">{{ $t('activity_log_report.activity_log_report')}}</h4>
                            <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                        </div>

                    </div>
                    <div class="row mb-10">
                        <div class="col-12" v-if="data_report_details">
                            <div class="timeline timeline-6 mt-3">
                                <!--begin::Item-->
                                <div class="timeline-item align-items-start" v-for="(row, index) in data_report_details" :key="index">
                                    <!--begin::Label-->
                                    <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg">{{row.created_at}}</div>
                                    <!--end::Label-->
                                    <!--begin::Badge-->
                                    <div class="timeline-badge">
                                        <i class="fa fa-genderless text-success icon-xl"></i>
                                    </div>
                                    <!--end::Badge-->
                                    <!--begin::Content-->
                                    <div class="timeline-content d-flex flex-column">
                                        <span class="font-weight-bolder text-dark-75 pl-3 pr-3 font-size-lg mb-3">{{$t('Added_By')}}: {{ row.user_name }} </span>
                                        <span class="font-weight-bolder text-dark-75 pl-3 pr-3 font-size-lg">{{row.description}} </span>
                                    </div>
                                    <!--end::Content-->
                                </div>
                                <!--end::Item-->
                                <div class="text-center" v-if="current_page < last_page">
                                    <button class="btn btn-warning" @click="loadMore">
                                        <p class="mb-0">
                                            <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                                            {{$t('load_more')}}
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--end::supplier-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-activity-log-report",
        data() {
            return {
                mainRoute: '/reports/system_activity_log',
                mainRouteDependency: 'base/dependency',

                data_report_details: [],
                actions: [
                    {slug: 'Create SalesInvoice', title: this.$t('activity_log_report.create_invoice')},
                    {slug: 'created PaymentSales', title: this.$t('activity_log_report.add_invoice_payment')},
                    {slug: 'created expenses', title: this.$t('activity_log_report.add_expense')},
                    {slug: 'created income', title: this.$t('activity_log_report.add_income')},
                    {slug: 'Created Customer', title: this.$t('activity_log_report.add_client')},
                    {slug: 'Created Item', title: this.$t('activity_log_report.add_product')},

                    {slug: 'pdf', title: this.$t('activity_log_report.print_reports') + ' (pdf)'},
                    {slug: 'excel', title: this.$t('activity_log_report.print_reports') + ' (excel)'},
                    {slug: 'update system settings', title: this.$t('activity_log_report.update_system_settings')},
                    {slug: 'login', title: this.$t('activity_log_report.login')},
                    {slug: 'inventory update', title: this.$t('activity_log_report.manual_stock_adjustment')},
                    // {slug: 'add_sold_stock', title: this.$t('activity_log_report.add_sold_stock')},
                ],


                filters: {
                    description: null,
                    user_id: null,
                    from_date: null,
                    to_date: null,
                },
                user: null,
                users: [],
                page: 0,
                current_page: 0,
                last_page: 0,
            }
        },
        watch: {
            user: function (val) {
                if (val) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.activity_log_report")}]);
        },
        methods: {
            getRandom() {
                return Math.floor(Math.random() * 10000);
            },
            doFilter() {
                this.page = 1;
                this.getReportDetails();

            },
            resetFilter() {
                this.filters.description = null;
                this.filters.user_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.user = null;

            },
            getUsers(filter) {
                if (filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
            },
            loadMore() {
                this.page = this.page + 1;

                this.getReportDetails();
            },
            getReportDetails() {
                ApiService.get(this.mainRoute, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
                    this.data_report_details = response.data.data.data;
                    this.current_page = response.data.data.current_page;
                    this.last_page = response.data.data.last_page;
                });
            },


        },
    };
</script>
